import React, { useState, useEffect } from 'react'
import { ListboxInput, ListboxButton, ListboxList, ListboxOption } from '@reach/listbox'
import '@reach/listbox/styles.css'
import '@reach/dialog/styles.css'
import { Plus } from 'react-feather'
import { graphql, useStaticQuery } from 'gatsby'
import { useQuery, gql } from '@apollo/client'
import { Client } from '../../../../sea-site-theme/src/apollo/client'
import useProduct from '../../../../sea-site-theme/src/hooks/useProduct'
import {
	AddProductBtn,
	Overlay,
	OverlayContent,
	OverlayHead,
	OverlayTitle,
	ActiveTab,
	CloseBtn,
	AddBtnHolder,
	Add,
	SelectionContainer,
	Popover,
	ModelImageContainer,
	ModelImage,
	PlusAdd,
} from './styles'

interface Tab {
	active: boolean
	brand: string
	slug: string
	tabName: string
	tag: string
}
interface AddProductProp {
	title: string
	active: Tab[]
	dolphinTag: Tab[]
	imageNode: {
		node: {
			images: {
				description: string
				fluid: {
					src: string
					srcWebp: string
				}
			}[]
			model: string
			node_locale: string
			seoTitle: string
			series: string
			slug: string
		}
	}[]
	getDatas: (x: string) => void
}

interface ModelImage {
	modelImage: {
		items: {
			seoTitle: string
			slug: string
			imagesCollection: {
				items: {
					url: string
				}[]
			}
		}[]
	}
}

interface ProductResult {
	imagesCollection: {
		items: {
			url: string
		}[]
	}
	model: string
	seoTitle: string
	tags: string[]
}

const APOLLO_PRODUCT_COMPARISON_QUERY = gql`
	query comparisonQuery($series: String!) {
		models: productCollection(
			locale: "ms-MY"
			where: { brand: "Dolphin", series: $series, countryAvailability_contains_all: ["MY"] }
			order: [popularity_DESC]
		) {
			items {
				seoTitle
				model
				tags
				imagesCollection {
					items {
						url
					}
				}
			}
		}
	}
`
const APOLLO_MODELIMAGE_QUERY = gql`
	query featureDetails($title: String!) {
		modelImage: productCollection(
			locale: "ms-MY"
			where: { seoTitle: $title, countryAvailability_contains_all: ["MY"] }
		) {
			items {
				seoTitle
				slug
				imagesCollection {
					items {
						url
					}
				}
			}
		}
	}
`

const AddProduct = (props: AddProductProp): JSX.Element => {
	const lines = useStaticQuery(graphql`
		query addProductQuery {
			residentialLines: allContentfulProduct(
				filter: { brand: { eq: "Dolphin" }, tags: { eq: "residential" }, countryAvailability: { eq: "MY" } }
			) {
				distinct(field: series)
				edges {
					node {
						model
						series
					}
				}
			}
			commercialLines: allContentfulProduct(
				filter: { brand: { eq: "Dolphin" }, tags: { eq: "commercial" }, countryAvailability: { eq: "MY" } }
			) {
				distinct(field: series)
				edges {
					node {
						model
						series
					}
				}
			}
			mineralswimLines: allContentfulProduct(
				filter: { brand: { eq: "Mineral Swim" }, countryAvailability: { eq: "MY" } }
			) {
				distinct(field: series)
				edges {
					node {
						model
						series
					}
				}
			}
			ozoneswimLines: allContentfulProduct(filter: { brand: { eq: "Ozone Swim" }, countryAvailability: { eq: "MY" } }) {
				distinct(field: series)
				edges {
					node {
						model
						series
					}
				}
			}
		}
	`)
	const { residentialLines, commercialLines } = lines
	const [showDialog, setShowDialog] = useState(false)
	const [value, setValue] = useState('default')
	const [modelValue, setModelValue] = useState('defaultModel')
	const [tabName, setTabName] = useState('')
	const [brand, setBrand] = useState('')
	const [slug, setSlug] = useState('')
	const [modelImage, setModelImage] = useState<ModelImage | undefined>(undefined)
	const open = () => setShowDialog(true)
	const close = () => setShowDialog(false)
	useEffect(() => {
		if (props.active.length > 0) {
			const [{ tabName, brand, slug }] = props.active
			setTabName(tabName)
			setBrand(brand)
			setSlug(slug)
		}
	}, [props.active])

	useEffect(() => {
		setModelValue('defaultModel')
	}, [value])

	useEffect(() => {
		setValue('default')
		setModelValue('defaultModel')
	}, [props.active])

	const { data } = useQuery(APOLLO_PRODUCT_COMPARISON_QUERY, {
		variables: { series: value },
		client: Client,
	})
	const { data: modelData } = useQuery(APOLLO_MODELIMAGE_QUERY, {
		variables: { title: modelValue },
		client: Client,
	})
	useEffect(() => {
		if (modelData != undefined && modelData.modelImage.items.length > 0) {
			setModelImage(modelData)
		}
	})
	const tag = props.dolphinTag.length > 0 ? props.dolphinTag[0].tag : ''
	const { addToTable, dataSummary } = useProduct()

	const prodimage =
		modelImage != undefined
			? props.imageNode.filter((x) => {
					return x.node.slug === modelImage.modelImage.items[0].slug
			  })
			: undefined

	return (
		<>
			<AddProductBtn onClick={() => open()}>
				<Plus color="#fff" />
				Add Product
			</AddProductBtn>
			<Overlay isOpen={showDialog} onDismiss={close} dangerouslyBypassFocusLock>
				<OverlayContent>
					<OverlayHead>
						<OverlayTitle>
							{props.title} <CloseBtn onClick={close} />
						</OverlayTitle>
						<ActiveTab>{tabName}</ActiveTab>
					</OverlayHead>

					<SelectionContainer>
						{brand === 'Dolphin' ? (
							<>
								<ListboxInput
									value={value}
									onChange={(value) => {
										setValue(value)
									}}
								>
									<ListboxButton arrow="▼" />
									<Popover as="div">
										<ListboxList>
											<ListboxOption value="default">Product Line</ListboxOption>
											{tag != ''
												? tag === 'residential'
													? residentialLines.distinct.map((line: string, i: number) => (
															<ListboxOption key={i} value={line}>
																{line}
															</ListboxOption>
													  ))
													: commercialLines.distinct.map((line: string, i: number) => (
															<ListboxOption key={i} value={line}>
																{line}
															</ListboxOption>
													  ))
												: ''}
										</ListboxList>
									</Popover>
								</ListboxInput>
								<ListboxInput value={modelValue} onChange={(modelValue) => setModelValue(modelValue)}>
									<ListboxButton arrow="▼" />
									<Popover as="div">
										<ListboxList>
											<ListboxOption value="defaultModel">Model</ListboxOption>
											{data != undefined
												? data.models.items
														.filter((x: ProductResult) => {
															return x.tags.includes(tag)
														})
														.map((x: ProductResult, i: number) => {
															return (
																<ListboxOption key={i} label={x.seoTitle} value={x.seoTitle}>
																	{x.seoTitle}
																</ListboxOption>
															)
														})
												: ''}
										</ListboxList>
									</Popover>
								</ListboxInput>
							</>
						) : (
							''
						)}
					</SelectionContainer>
					{prodimage != undefined ? (
						<ModelImageContainer>
							<ModelImage>
								<source
									srcSet={prodimage != undefined ? prodimage[0].node.images[0].fluid.srcWebp : ''}
									type="image/webp"
								></source>
								<source
									srcSet={
										prodimage != undefined
											? `${prodimage[0].node.images[0].fluid.src.split('?')[0]}?fm=png&fl=png8&fit=pad&w=600&h=400`
											: ''
									}
								></source>
								<img
									src={prodimage != undefined ? prodimage[0].node.images[0].fluid.src : ''}
									alt={prodimage != undefined ? prodimage[0].node.images[0].description : ''}
								/>
							</ModelImage>
						</ModelImageContainer>
					) : (
						''
					)}
					<AddBtnHolder>
						<Add
							onClick={() => {
								close()
								setValue('default')
								setModelValue('defaultModel')
								setModelImage(undefined)
								addToTable(slug, tag ? tag : '', modelValue)
								props.getDatas(dataSummary(tag ? tag : slug))
							}}
						>
							<PlusAdd width="16" height="16" color="#fff" />
							Add to comparison
						</Add>
					</AddBtnHolder>
				</OverlayContent>
			</Overlay>
		</>
	)
}

export default AddProduct
